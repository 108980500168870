@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-scrollbar {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */

  &::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent; /* Color of the thumb */
  }
}

.modal-open {
  @apply overflow-hidden;
}

.blur {
  @apply filter blur-sm;
}

body {
  height: 100vh;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  @apply flex flex-col items-center;
}

#root {
  @apply w-full max-w-[1536px];
}

@media (min-width: 768px) {
  #root {
    @apply min-w-[1280px];
  }
}

@media (max-width: 767px) {
  #root {
    @apply max-w-[450px];
    @apply min-w-[320px];
  }
}

h2 {
  @apply text-4xl font-bold;
}

h3 {
  @apply text-2xl font-semibold;
}

h4 {
  @apply text-xl font-medium;
}

.highlight {
  animation: highlight-animation 1s ease-in-out 0s 2;
}

@keyframes highlight-animation {
  0% {
    background-color: yellow;
  }
  100% {
    background-color: transparent;
  }
}
